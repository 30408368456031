.hamburger-menu {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

#toggle {
  display: none;
}

.hamburger {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 10px;
}

.bar1,
.bar2,
.bar3 {
  width: 100%;
  height: 3px;
  background-color: #fff; /* Changed to white */
  margin: 6px 0;
  transition: 0.4s;
}

/* Rotate animation */
#toggle:checked + .hamburger .bar1 {
  transform: rotate(-45deg) translate(-5px, 5px);
  background-color: #fff; /* Ensure white color when rotated */
}

#toggle:checked + .hamburger .bar2 {
  opacity: 0;
}

#toggle:checked + .hamburger .bar3 {
  transform: rotate(45deg) translate(-8px, -8px);
  background-color: #fff; /* Ensure white color when rotated */
}

.nav {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%; 
  z-index: 999;
  transition: 0.5s;
  cursor: pointer; 
}

.hamburger-menu.open .nav { 
  left: 0; 
}

.nav-wrapper {
  position: relative;
  width: 15%;
  height: 100%;
  padding: 30px;
  margin-top: 70px;
}

nav {
  text-align: left;
}

nav a {
  display: block;
  font-size: 24px;
  color: #fff; /* Updated color to white */
  margin-bottom: 20px;
  text-decoration: none;
  padding-bottom: 10px;
}

nav a:hover {
  color: #ccc; /* Updated hover color */
}
