/* InitiatePasswordReset.css */
.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #090d00;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 0.5rem;
}

.reset-password-form h2 {
  color: white;
  margin-bottom: 2rem;
}

.reset-password-form .form-group {
  margin-bottom: 1.5rem;
  width: 100%;
}

.reset-password-form .form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: white !important; /* Set label color to white with higher specificity */
  font-size: 1rem;
}

.reset-password-form .form-group input[type="text"],
.reset-password-form .form-group input[type="email"],
.reset-password-form .form-group input[type="password"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background-color: #f8f8f8;
  color: black; /* Ensure input text color is black */
}

.reset-password-form .reset-password-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #6f73e3;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

.reset-password-form .reset-password-button:hover {
  background-color: #5a60b7;
}

.reset-password-container p {
  color: white; /* Ensure message text color is white */
  margin-top: 1rem;
}

/* Back Button Styles */
.back-arrow {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.back-arrow svg {
  fill: white;
}
