.welcome-image {
  width: 80%;
  height: auto; 
  object-fit: cover;
  border-radius: 1.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 4px solid #fff;
  position: relative;
  filter: brightness(1.1) contrast(1.2);
  max-width: 50rem; 
  margin: 1.25rem; 
}

.welcome-message h2, .inspirational-quote p {
  color: #FFFFFF;
  text-align: center;
  font-size: 2rem;
  margin: 1.25rem 0; 
}

.welcome-message p {
  color: #FFFFFF;
  text-align: center;
  font-size: 1.25rem;
}

.action-items {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  margin-top: 1.25rem; 
  flex-wrap: wrap;
}

.action-button {
  padding: 0.625rem 1.25rem; 
  background-color: #6f73e3;
  color: #FFFFFF;
  border: none;
  border-radius: 0.3125rem; 
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem; 
}

.action-button:hover {
  background-color: #6422b5;
}

.inspirational-quote {
  margin-top: 1.875rem; 
  font-style: italic;
  text-align: center;
  color: #FFFFFF;
  font-size: 1.125rem; 
}

.link-style-button {
  display: flex;
  justify-content: center;
  width: 100%;
}

#signupButton {
  text-decoration: none;
  font-weight: bolder;
}

@media (max-width: 48rem) { 
  .welcome-message h2 {
    font-size: 1.75rem;
  }

  .welcome-message p, .inspirational-quote p {
    font-size: 1rem;
  }

  .action-button {
    padding: 0.5rem 0.9375rem; 
    font-size: 0.875rem;
  }
}

@media (max-width: 30rem) { 
  .welcome-image {
    width: 95%;
    margin: 0.625rem auto; 
  }

  .welcome-message h2 {
    font-size: 1.5rem;
  }

  .welcome-message p, .inspirational-quote p {
    font-size: 0.875rem;
  }
}
