.create-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem; /* Slightly reduced padding */
}

.create-account-form {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem; /* Slightly reduced padding */
  border-radius: 0.625rem; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 56.25rem;
  min-height: 60vh;
}

.form-group {
  margin-bottom: 0.25rem; /* Further reduced margin to decrease spacing between fields */
}

.form-group label {
  display: block;
  margin-bottom: 0.125rem; /* Reduced margin to make labels closer to inputs */
  color: #333;
}

.form-group input[type="text"],
.form-group input[type="number"] {
  width: 96%; 
  padding: 0.25rem; /* Reduced padding to make inputs smaller */
  border: 1px solid #ccc;
  border-radius: 0.3125rem; 
  background-color: #f8f8f8;
  box-sizing: border-box; 
  height: 4vh; /* Reduced height */
}

.create-button {
  width: 100%;
  padding: 0.5rem; /* Reduced padding to make the button height more reasonable */
  border: none;
  border-radius: 0.3125rem; 
  background-color: #6f73e3;
  color: white;
  cursor: pointer;
  font-size: 1rem; /* Reduced font size */
}

.create-button:hover {
  background-color: #6422b5;
}

@media (max-width: 48rem) { 
  .create-account-form {
    padding: 0.75rem;
    max-width: 80%; 
  }

  .custom-font-admin {
    font-size: 1.5rem;
  }
  
  .form-group input[type="text"],
  .form-group input[type="number"] {
    padding: 0.5rem; 
  }
}

@media (max-width: 30rem) { 
  .create-account-form {
    max-width: 90%; 
  }

  .create-button {
    padding: 0.625rem;
    font-size: 0.875rem; 
  }
}
