.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center; 
}

.star-icon {
  width: 6.875rem; 
  height: auto;
}

.star-count {
  font-size: 1.5em; 
  font-weight: bold;
}

.star-box {
  display: flex;
  align-items: center;
}

.forms-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1.25rem; 
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  max-width: 1200px; 
  margin: 1.25rem auto; 
}

.upper-forms-container {
  display: flex;
  justify-content: space-between; 
  width: 100%;
  margin-bottom: 1.25rem; 
}

.lower-forms-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profile-form, .nfc-form {
  flex: 1; 
  background: #fff;
  padding: 0.9375rem; 
  border-radius: 0.5rem; 
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-right: 0.625rem; 
}

.profile-form:last-child, .nfc-form:last-child {
  margin-right: 0;
}

.student-info-section, .nfc-management {
  flex: 1; 
  background: #fff;
  padding: 0.9375rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-left: 0.625rem;
}

.form-group {
  margin-bottom: 0.9375rem; 
}

.form-group label {
  display: block;
  margin-bottom: 0.3125rem; 
  color: #000 !important; /* Ensure text is black */
  font-size: 0.875rem;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea {
  width: 100%; 
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem; 
  background-color: #f8f8f8;
  color: #000; /* Ensure text is black */
  box-sizing: border-box; /* Ensure consistent box-sizing */
}

.form-group input[type="text"]::placeholder,
.form-group input[type="number"]::placeholder,
.form-group textarea::placeholder {
  color: #aaa; /* Placeholder text color */
}

.submit-button {
  width: 100%;
  padding: 0.625rem; 
  border: none;
  border-radius: 0.25rem; 
  background-color: #6f73e3;
  color: white;
  cursor: pointer;
  font-size: 0.9375rem; 
  margin-top: 0.3125rem; 
}

.submit-button:hover, .nfc-submit-button:hover {
  background-color: #5a60b7;
}

/* Remove jumping/moving effect on hover */
.profile-form:hover, .nfc-form:hover {
  transform: none; 
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 48rem) { 
  .upper-forms-container, .lower-forms-container {
    flex-direction: column;
  }

  .profile-form, .nfc-form, .student-info-section, .nfc-management {
    margin: 0 0 0.625rem 0; 
    width: 100%; 
  }

  .profile-form:last-child, .nfc-form:last-child {
    margin-bottom: 0;
  }
}

.textarea-reason {
  width: 100%; /* Ensure full width */
  height: 10rem; 
  resize: none; 
  color: #000; /* Ensure text is black */
  box-sizing: border-box; /* Ensure consistent box-sizing */
}

.ninja-image-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 1.25rem; 
}

.ninja-image-container img {
  max-width: 40%; 
  height: auto; 
}

.ninjaImg {
  animation: float 4s ease-in-out infinite;
}

/* Reset any existing margin or padding */
.form-group {
  position: relative;
  margin: 0; /* Reset margins */
  padding: 0; /* Reset padding */
}

input#impactSearch {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc; /* Ensure a consistent border */
  border-radius: 0.25rem;
  box-sizing: border-box; /* Ensure consistent box-sizing */
  margin: 0; /* Reset margins to avoid extra space */
  outline: none; /* Remove any outline that might appear when the input is focused */
}

input#impactSearch:focus {
  border: 1px solid #ccc; /* Keep the border on focus */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow on focus */
}

.search-results-list {
  list-style-type: none;
  padding: 0;
  margin: 0; /* Ensure no extra space around the dropdown */
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  top: calc(100% + 2px); /* Ensure the dropdown is right below the input */
}

.search-results-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  color: #000; /* Set text color to black or another visible color */
  background-color: #f9f9f9; /* Slightly gray background */
}

.search-results-list li:hover {
  background-color: #e0e0e0; /* Darker gray on hover */
}

.search-results-list li:last-child {
  border-bottom: none;
}

.form-group {
  overflow: visible; /* Prevents clipping of the dropdown */
}

input#impactSearch {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc; /* Ensure a consistent border */
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin: 0;
  outline: none;
}

input#impactSearch:focus {
  border: 1px solid #ccc; /* Keep the border on focus */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow on focus */
}

.profile-header {
  padding-top: 0; /* Ensure no padding at the top */
}

.profile-header .form-group {
  padding-top: 10px; /* Move the whole group down within the container */
}

.profile-header .form-group label {
  margin-top: 0; /* Removes any top margin */
}

#impactSearch {
  margin-top: 0;
}

.transaction-list-container {
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.9); /* Background color matching other boxes */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a slight elevation effect */
  padding: 0.9375rem; /* Internal padding */
  margin: 1.25rem auto; /* Center and margin similar to other boxes */
  width: 100%; /* Ensure it matches the width of the other sections */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.transaction-history-section {
  width: 100%; /* Ensure the section takes up the full width */
}

.transaction-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.transaction-list li {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  color: #000; /* Ensure text color is black */
}

.transaction-list li:last-child {
  border-bottom: none;
}

/* Ensure the h2 styling matches other headers */
.transaction-history-section h2 {
  font-size: 1.5rem; /* Adjust the font size */
  color: #000; /* Ensure the text color is black */
  margin-bottom: 0.5rem; /* Space below the header */
  text-align: left; /* Align text to the left to match other sections */
}
