.reset-password-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    z-index: 10;
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    z-index: 10;
  }
  
  .form-group {
    margin-bottom: 15px;
    color: white;
  }
  
  .form-group label {
    color: white;
  }
  
  .form-group input {
    color: black !important;
    background-color: white !important;
    border: 1px solid #ccc !important;
    padding: 6px 10px !important; /* Adjust padding for height */
    border-radius: 5px !important;
    font-size: 14px !important; /* Adjust font size for better readability */
    height: 40px !important; /* Set a fixed height */
    box-sizing: border-box; /* Ensure padding and border are included in the height */
  }
  
  .reset-password-button {
    background-color: #6200ea;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .reset-password-button:hover {
    background-color: #3700b3;
  }
  