@keyframes delay {
    0%, 40%, 100% { 
      transform: scaleY(0.05);
    }
    20% { 
      transform: scaleY(1.0);
    }
  }
  
  .overlay {
    position: fixed;
    height: 50vh;
    width: 100%;
    left: 0;
    background: #1c2020;
    z-index: 9999;
  }
  
  .overlay.top {
    top: 0;
    transform: translateY(-100%);
  }
  
  .overlay.bottom {
    bottom: 0;
    transform: translateY(100%);
  }
  
  
  .loader {
    display: flex;
    justify-content: center; 
    align-items: center;
    margin: 0 auto;
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0; 
    z-index: 9999;
  }
  
  .loader > div {
    height: 100%;
    width: 8px;
    margin: 0 2px;
    animation: delay 0.8s infinite ease-in-out;
    z-index: 9999;
  }
  
  .loader .bar1 { background-color: #754fa0; }
  .loader .bar2 { background-color: #09b7bf; animation-delay: -0.7s; }
  .loader .bar3 { background-color: #90d36b; animation-delay: -0.6s; }
  .loader .bar4 { background-color: #f2d40d; animation-delay: -0.5s; }
  .loader .bar5 { background-color: #fcb12b; animation-delay: -0.4s; }
  .loader .bar6 { background-color: #ed1b72; animation-delay: -0.3s; }

  .btn {
    position: fixed; 
    bottom: 20px; 
    left: 45%;
    padding: 0.6em 1.2em;
    border-radius: 50em;
    background-color: #1c2020;
    max-width: 200px;
    width: auto;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 9998; 
  }
  
  .btn:hover {
    background-color: #333; 
  }
  
  